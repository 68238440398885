import React, { FunctionComponent } from 'react';
import Section from "../components/Section";
import Hero from "../components/Hero";
import Heading from "../components/Heading";
import useTranslations from "../components/useTranslations";

type SroProps = {
};

const Sro: FunctionComponent<SroProps> = (props) => {
    const { pages: { policies } } = useTranslations()

    return (
        <>
            <Section className='py-8' fullWidth>
                <Hero
                    outerContainer
                    headline={policies.headline}
                />
            </Section>
            <Section className={'py-20'}>
                {policies.items.map((item, policy_index) => {
                    policy_index;
                    return (
                        <div key={`policy-parent-${policy_index + 1}`} className={'mb-14'}>
                            <Heading type="h3" className={'text-center font-semibold mb-2'}>{`${policies.article} ${policy_index + 1}`}</Heading>
                            <Heading type="h4" className={'mb-6 text-center'}>{item.title}</Heading>
                            {item.children.map((child, child_index) => {
                                return (
                                    <>
                                        <p key={`policy-${policy_index + 1}.${child_index + 1}`} className={'pb-6'}>{`${policy_index + 1}.${child_index + 1}`} {child.text}</p>
                                        {child.children && child.children.map((child_2, child_index_2) => {
                                            return (
                                                <p key={`policy-${policy_index + 1}.${child_index + 1}.${child_index_2}`} className={'pb-6'}>{child_2.text}</p>
                                            )
                                        })}
                                    </>
                                )
                            })}
                        </div>
                    )
                })}
            </Section>
        </>
    );
};

export default Sro;
